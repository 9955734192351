<template>
  <div></div>
</template>
<script>
import store from "../store";
import axios from "axios";
// import md5 from "md5";
// import Swal from "sweetalert2";
export default {
  data: () => ({
    userInformation: store.state,
  }),
  created() {
    // this.processStart();
    console.log(this.$route.query.email);
    this.go();
  },
  methods: {
    processStart() {
      //   this.redirectedLogout();
    },
    go: function() {
      let email = this.$route.query.email;
      let url = `${this.api}getUserRedirectedFromCadRequest/${email}`;

      // console.log('line286', url)

      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      axios.post(url).then((res) => {
        // console.log('line294',res.data)

        if (res.data != "error" && res.data != "User does not exists!") {
          let toInsertUserLog = {
            MailAddress: this.mailAddress, // 2022-02-07
            count: 1,
            name: res.data.user_info.Name, // 2022-02-14
          };
          // let date = moment();
          // this.setExpiryDate(date.format("YYYY-MM-DD"));
          // console.log();
          // =========================== OLD ============================= //
          // console.log('EMAIL', res.data.user_info)
          // let info = {
          //   name: res.data.user_info.Name,
          //   id: res.data.user_info.MailAddress,
          //   isAdmin: res.data.user_info.isAdmin,
          //   employeeCode: res.data.user_info.UserId,
          //   // isAdmin: true,

          //   isRulebookUser: res.data.user_info.isRulebookUser,
          //   isGCUser: res.data.user_info.isGCUser,
          //   isIchijoUser: res.data.user_info.isIchijoUser
          // };
          // // // ////DATA FOR USER RANK////
          // // this.userLogData = {
          // //   user: res.data.user_info.Name,
          // //   email: res.data.user_info.MailAddress,
          // //   accessDates: [],
          // //   id: shortid.generate().substring(0,5),
          // //   employeeCode: res.data.user_info.UserId,
          // // }
          // // console.log('------------------222222------------------');
          // // console.log(this.userLogData);
          // // console.log('------------------------------------');
          // this.ADD_INFO(info);
          // this.$store.commit("AUTH_SUCCESS", res.data.accessToken);
          // this.$router.push("/");

          // this.loadUserLogs() // tanskie
          // ================================================================= //

          // ====================== tanskie 2022-02-07 ======================= //
          let insertLogUrl = `${this.api}userLogs/insertUserLog`;

          axios
            .post(insertLogUrl, toInsertUserLog)
            .then((res1) => {
              if (res1.data) {
                let info = {
                  name: res.data.user_info.Name,
                  id: res.data.user_info.MailAddress,
                  isAdmin: res.data.user_info.isAdmin,
                  employeeCode: res.data.user_info.UserId,
                  // isAdmin: true,
                  isRulebookUser: res.data.user_info.isRulebookUser,
                  isGCUser: res.data.user_info.isGCUser,
                  isIchijoUser: res.data.user_info.isIchijoUser,
                  isGCPortalUser: res.data.user_info.isGCPortalUser, // 2022-09-06
                  isIncharge: res.data.user_info.isIncharge, // 2022-09-06
                  isSalesUser: res.data.user_info.isSalesUser, // 2023-04-19
                  originalMailAddress: res.data.user_info.OriginalMailAddress, // 2022-02-12
                };
                // // ////DATA FOR USER RANK////
                // this.userLogData = {
                //   user: res.data.user_info.Name,
                //   email: res.data.user_info.MailAddress,
                //   accessDates: [],
                //   id: shortid.generate().substring(0,5),
                //   employeeCode: res.data.user_info.UserId,
                // }
                // console.log('------------------222222------------------');
                // console.log(this.userLogData);
                // console.log('------------------------------------');
                this.redirectedLogout();
                this.ADD_INFO(info);
                this.$store.commit("AUTH_SUCCESS", res.data.accessToken);
                this.$router.push("/");
                this.$router.push({
                  path: "/",
                  query: {
                    tabnum: "6",
                  },
                });

                // this.loadUserLogs(); // tanskie
              }
            })
            .catch((err1) => {
              console.log(err1.message);
            });
        }
        // else if (res.data == "User does not exists!") {
        //   Swal.fire({
        //     icon: "error",
        //     title: res.data,
        //     showConfirmButton: false,
        //     timer: 1500,
        //   });
        //   this.userPass = "";
        //   this.mailAddress = "";
        // } else {
        //   Swal.fire({
        //     icon: "error",
        //     title: "Invalid Password!",
        //     showConfirmButton: false,
        //     timer: 1500,
        //   });
        //   this.userPass = "";
        // }
      });
    },
  },
};
</script>
<style></style>
